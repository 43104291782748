import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "dropdown-item",
    to: _ctx.link
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon"),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["to"]))
}