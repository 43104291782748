export default {
  DASHBOARD: "/",

  PRODUCTS: "/eshop/products",
  PRODUCTS_CREATE: "/eshop/products/create",
  PRODUCTS_IMPORT: "/eshop/products/import",

  PRODUCT__ID: "/eshop/products/:id",
  _PRODUCT_PRESENTATION: "presentation",
  _PRODUCT_SALES: "sales",
  _PRODUCT_METADATA: "metadata",

  CATEGORIES: "/eshop/categories",
  CATEGORY__ID: "/eshop/categories/:id",
  _CATEGORY_PRESENTATION: "presentation",
};
