import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import { Category_DetailDto, CategoryService } from "@/schema";

interface CategoriesState {
  categories: Array<Category_DetailDto> | null;
}

const state: CategoriesState = {
  categories: null,
};

const mutations: MutationTree<CategoriesState> = {};

const actions: ActionTree<CategoriesState, RootState> = {
  fetch(context): Promise<void> {
    context.state.categories = null;

    return new Promise((resolve) => {
      CategoryService.getCategories().then(
        (categories: Array<Category_DetailDto>) => {
          context.state.categories = categories;
          resolve();
        }
      );
    });
  },
};

const getters: GetterTree<CategoriesState, RootState> = {
  byUuid:
    (state) =>
    (uuid: string): Category_DetailDto | null => {
      const filtered = state.categories?.filter((x) => x.uuid === uuid);
      return filtered?.length ? filtered[0] : null;
    },

  all(state) {
    return state.categories;
  },

  childrens:
    (state) =>
    (left: number, right: number, level: number): Array<Category_DetailDto> => {
      return (
        state.categories?.filter(
          (x) =>
            x.tree_left &&
            x.tree_left > left &&
            x.tree_right &&
            x.tree_right < right &&
            x.tree_level === level
        ) ?? []
      );
    },
};

export const categoriesModule: Module<CategoriesState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
