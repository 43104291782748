/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Product_Create } from '../models/Product_Create';
import type { Product_DetailDto } from '../models/Product_DetailDto';
import type { Product_UpdateDto } from '../models/Product_UpdateDto';
import type { ProductImage_Add } from '../models/ProductImage_Add';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductService {

    /**
     * @param id 
     * @param flag 
     * @returns Product_DetailDto Success
     * @throws ApiError
     */
    public static flagProduct(
id: string,
flag: string,
): CancelablePromise<Product_DetailDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/backoffice/eshop/products/{id}/flag/{flag}',
            path: {
                'id': id,
                'flag': flag,
            },
        });
    }

    /**
     * @param id 
     * @param flag 
     * @returns Product_DetailDto Success
     * @throws ApiError
     */
    public static unflagProduct(
id: string,
flag: string,
): CancelablePromise<Product_DetailDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/backoffice/eshop/products/{id}/flag/{flag}',
            path: {
                'id': id,
                'flag': flag,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Product_DetailDto Success
     * @throws ApiError
     */
    public static changeMainImage(
id: string,
requestBody: ProductImage_Add,
): CancelablePromise<Product_DetailDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/eshop/products/{id}/image/main',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Product_DetailDto Success
     * @throws ApiError
     */
    public static addGalleryImage(
id: string,
requestBody: ProductImage_Add,
): CancelablePromise<Product_DetailDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/eshop/products/{id}/image/gallery',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Product_DetailDto Success
     * @throws ApiError
     */
    public static getProduct(
id: string,
): CancelablePromise<Product_DetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/backoffice/eshop/products/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Product_DetailDto Success
     * @throws ApiError
     */
    public static updateProduct(
id: string,
requestBody: Product_UpdateDto,
): CancelablePromise<Product_DetailDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/backoffice/eshop/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Product_DetailDto Success
     * @throws ApiError
     */
    public static getProducts(): CancelablePromise<Array<Product_DetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/backoffice/eshop/products',
        });
    }

    /**
     * @param requestBody 
     * @returns Product_DetailDto Success
     * @throws ApiError
     */
    public static createProduct(
requestBody: Product_Create,
): CancelablePromise<Product_DetailDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/eshop/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
