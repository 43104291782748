
import { defineComponent, onMounted } from "vue";
import {
  HomeIcon,
  BuildingWarehouseIcon,
  CategoryIcon,
} from "vue-tabler-icons";
import NavigationItem from "@/views/GenericComponents/Tabler/NavigationItem.vue";
import NavigationDropdown from "@/views/GenericComponents/Tabler/NavigationDropdown.vue";
import NavigationDropdownItem from "@/views/GenericComponents/Tabler/NavigationDropdownItem.vue";
import paths from "@/router/paths";
import NavigationSeparatorLabel from "@/views/GenericComponents/Tabler/NavigationSeparatorLabel.vue";

export default defineComponent({
  props: {
    containerBody: {
      type: String,
      default: "container-xl",
      required: false,
    },
  },
  components: {
    NavigationSeparatorLabel,
    NavigationDropdownItem,
    NavigationDropdown,
    HomeIcon,
    BuildingWarehouseIcon,
    CategoryIcon,
    NavigationItem,
  },
  setup() {
    return {
      paths,
    };
  },
});
