
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import NotificationToast from "@/views/GenericComponents/UI/Notifications/NotificationToast.vue";

export default defineComponent({
  components: { NotificationToast },
  setup() {
    // Dependencies
    const store = useStore();

    return {
      notifications: computed(() => store.getters["flash/messages"]),
    };
  },
});
