export default class FlashMessage {
  static readonly TYPE_ERROR: string = "toast-error";
  static readonly TYPE_SUCCESS: string = "toast-success";
  static readonly TYPE_INFO: string = "toast-info";

  title: string;
  text: string;
  type: string;
  created: Date;

  constructor(title: string, text: string, type: string) {
    this.title = title;
    this.text = text;
    this.type = type;

    this.created = new Date();
  }
}
