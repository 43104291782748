import { createStore } from "vuex";
import { flashMessageModule } from "@/store/flash";
import { productsModule } from "@/store/products";
import { productModule } from "@/store/product";
import { categoriesModule } from "@/store/categories";
import { categoryModule } from "@/store/category";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export const store = createStore<RootState>({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    flash: flashMessageModule,
    categories: categoriesModule,
    category: categoryModule,
    products: productsModule,
    product: productModule,
  },
});
