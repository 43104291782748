import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-item" }
const _hoisted_2 = { class: "nav-link-icon d-inline-block" }
const _hoisted_3 = { class: "nav-link-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "nav-link",
      to: _ctx.link
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "icon")
        ]),
        _createElementVNode("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _: 3
    }, 8, ["to"])
  ]))
}