import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import { Category_DetailDto, CategoryService } from "@/schema";

interface CategoryState {
  category: Category_DetailDto | null;
}

const state: CategoryState = {
  category: null,
};

const mutations: MutationTree<CategoryState> = {};

const actions: ActionTree<CategoryState, RootState> = {
  fetch(context, uuid: string): Promise<void> {
    context.state.category = null;

    return new Promise((resolve) => {
      CategoryService.getCategory(uuid).then((category: Category_DetailDto) => {
        context.state.category = category;
        resolve();
      });
    });
  },
};

const getters: GetterTree<CategoryState, RootState> = {
  get(state): Category_DetailDto {
    return state.category as Category_DetailDto;
  },
};

export const categoryModule: Module<CategoryState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
