
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const visible = ref<boolean>(false);

    const close = () => {
      visible.value = false;
      setTimeout(() => store.dispatch("flash/remove", props.index), 250);
    };

    onMounted(() => {
      setTimeout(close, 6500);

      visible.value = true;
    });

    return {
      notification: store.getters["flash/single"](props.index),
      visible,
      close,
    };
  },
});
