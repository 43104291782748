import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import { Product_DetailDto, ProductService } from "@/schema";

interface ProductState {
  product: Product_DetailDto | null;
}

const state: ProductState = {
  product: null,
};

const mutations: MutationTree<ProductState> = {};

const actions: ActionTree<ProductState, RootState> = {
  fetch(context, uuid: string): Promise<void> {
    return new Promise((resolve) => {
      ProductService.getProduct(uuid).then((product: Product_DetailDto) => {
        context.state.product = product;
        resolve();
      });
    });
  },
};

const getters: GetterTree<ProductState, RootState> = {
  get(state): Product_DetailDto {
    return state.product as Product_DetailDto;
  },
};

export const productModule: Module<ProductState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
