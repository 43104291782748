import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/index";
import FlashMessage from "@/store/flash/flashMessage";

interface FlashMessageState {
  messages: Map<number, FlashMessage>;
}

const state: FlashMessageState = {
  messages: new Map<number, FlashMessage>(),
};

const mutations: MutationTree<FlashMessageState> = {
  add(state, flash: FlashMessage) {
    state.messages.set(Math.random(), flash);
  },

  remove(state, index: number) {
    state.messages.delete(index);
  },
};

const actions: ActionTree<FlashMessageState, RootState> = {
  add({ commit }, flash: FlashMessage) {
    commit("add", flash);
  },

  create(
    context,
    payload: {
      title: string;
      text: string;
      type: string;
    }
  ): void {
    context.commit(
      "add",
      new FlashMessage(payload.title, payload.text, payload.type)
    );
  },

  remove({ commit }, index: number) {
    commit("remove", index);
  },
};

const getters: GetterTree<FlashMessageState, RootState> = {
  messages(state) {
    return state.messages;
  },

  single: (state) => (index: number) => {
    return state.messages.get(index);
  },
};

export const flashMessageModule: Module<FlashMessageState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
