import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "@/views/Views/DashboardView.vue";
import paths from "@/router/paths";

const routes: Array<RouteRecordRaw> = [
  {
    path: paths.DASHBOARD,
    component: Dashboard,
  },

  {
    path: paths.PRODUCTS,
    component: () =>
      import("@/views/Views/Eshop/Products/List/ProductsView.vue"),
  },
  {
    path: paths.PRODUCTS_CREATE,
    component: () =>
      import("@/views/Views/Eshop/Products/Create/CreateView.vue"),
  },
  {
    path: paths.PRODUCTS_IMPORT,
    component: () =>
      import("@/views/Views/Eshop/Products/Import/ImportView.vue"),
  },
  {
    path: paths.PRODUCT__ID,
    component: () =>
      import("@/views/Views/Eshop/Products/Detail/ProductDetailView.vue"),
    children: [
      {
        alias: "",
        path: paths._PRODUCT_PRESENTATION,
        component: () =>
          import(
            "@/views/Views/Eshop/Products/Detail/Presentation/ProductDetailPresentation.vue"
          ),
      },
      {
        alias: "",
        path: paths._PRODUCT_SALES,
        component: () =>
          import(
            "@/views/Views/Eshop/Products/Detail/Sales/ProductDetailSales.vue"
          ),
      },
      {
        alias: "",
        path: paths._PRODUCT_METADATA,
        component: () =>
          import(
            "@/views/Views/Eshop/Products/Detail/Metadata/ProductDetailMetadata.vue"
          ),
      },
    ],
  },

  {
    path: paths.CATEGORIES,
    component: () =>
      import("@/views/Views/Eshop/Categories/List/CategoriesView.vue"),
  },
  {
    path: paths.CATEGORY__ID,
    component: () =>
      import("@/views/Views/Eshop/Categories/Detail/CategoryDetailView.vue"),
    children: [
      {
        alias: "",
        path: paths._CATEGORY_PRESENTATION,
        component: () =>
          import(
            "@/views/Views/Eshop/Categories/Detail/Presentation/CategoryDetailPresentation.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active",
});

export default router;
