import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e970060"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-link dropdown-toggle" }
const _hoisted_2 = { class: "nav-link-icon d-inline-block" }
const _hoisted_3 = { class: "nav-link-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    class: _normalizeClass(`nav-item dropdown cursor-pointer ${_ctx.visible ? 'show' : ''}`)
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(`dropdown-menu ${_ctx.visible ? 'show' : ''}`)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}