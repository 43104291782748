import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3a0a348"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationToast = _resolveComponent("NotificationToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications.keys(), (i) => {
      return (_openBlock(), _createBlock(_component_NotificationToast, {
        key: i,
        index: i
      }, null, 8, ["index"]))
    }), 128))
  ]))
}