/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Category_Create } from '../models/Category_Create';
import type { Category_DetailDto } from '../models/Category_DetailDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoryService {

    /**
     * @returns Category_DetailDto Success
     * @throws ApiError
     */
    public static getCategories(): CancelablePromise<Array<Category_DetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/backoffice/eshop/categories',
        });
    }

    /**
     * @param requestBody 
     * @returns Category_DetailDto Success
     * @throws ApiError
     */
    public static createCategory(
requestBody: Category_Create,
): CancelablePromise<Category_DetailDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/eshop/categories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Category_DetailDto Success
     * @throws ApiError
     */
    public static getCategory(
id: string,
): CancelablePromise<Category_DetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/backoffice/eshop/categories/{id}',
            path: {
                'id': id,
            },
        });
    }

}
