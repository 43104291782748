import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import { Product_DetailDto, ProductService } from "@/schema";

interface ProductsState {
  products: Array<Product_DetailDto>;
}

const state: ProductsState = {
  products: Array<Product_DetailDto>(),
};

const mutations: MutationTree<ProductsState> = {
  fetch(state): Promise<void> {
    return new Promise((resolve) => {
      ProductService.getProducts().then(
        (products: Array<Product_DetailDto>) => {
          state.products = products;
          resolve();
        }
      );
    });
  },
};

const actions: ActionTree<ProductsState, RootState> = {};

const getters: GetterTree<ProductsState, RootState> = {
  get(state) {
    return state.products;
  },
};

export const productsModule: Module<ProductsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
