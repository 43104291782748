
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    return {
      visible: ref<boolean>(false),
    };
  },
});
